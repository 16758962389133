<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-12"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        sm="8"
        md="8"
      >
        <v-card class="font-size-medium text-body-2 ">
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="d-flex justify-space-between ml-20 table-fields grey py- lighten-4"
          >
            <div>
              <div class="justify-start font-size-medium text-body-2 grey-lighten-2 px-auto">
                {{ currentuser.firstname + " " + currentuser.lastname }}
              </div><span class="font-size-caption">{{ currentuser.email }}</span>
            </div>
            <span><v-btn
              :disabled="loading"
              class="ma-1 text-subtitle-2 mr-n18"
              plain
              text
              color="blanketbutton"
              @click="editUserRedirect"
            >
              <span class="font-size-medium text-body-2 grey-lighten-2"> <v-icon class="pr-1 py-2">
                mdi-pencil
              </v-icon>Edit Info</span>
            </v-btn></span>
          </v-col>
          <v-form>
            <v-container>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-card-text
                  class="font-weight-light mx-auto ml-n2 class=font-size-medium text-body-2"
                >
                  <div
                    class=" ml-2  my-2 font-weight-bold"
                  >
                    ABOUT
                  </div>
                  <div class="d-flex my-2 justify-space-between">
                    <span grey-lighten-2><v-icon class="pr-1 grey-lighten-2 y-auto">
                      mdi-cake
                    </v-icon>
                      Birthday
                    </span>
                    <span>{{ currentuser.dob }}</span>
                  </div>
                  <div class="d-flex justify-space-between my-2 align-center py-2 grey-lighten-2">
                    <span grey-lighten-2><v-icon class="pr-1 grey-lighten-2">
                      mdi-scale
                    </v-icon>
                      Weight
                    </span>
                    <span>{{ currentuser.weight.kg ? currentuser.weight.kg : currentuser.weight.lb }}</span>
                  </div>
                  <div class="d-flex justify-space-between  my-2 align-center grey-lighten-2 py-2">
                    <span grey-lighten-2><v-icon class="pr-1 grey-lighten-2">
                      mdi-arrow-up-down
                    </v-icon>
                      Height
                    </span>
                    <span>{{ currentuser.height.centimeters ? currentuser.height.centimeters : currentuser.height.feet + " " + currentuser.height.inches + "'" }}</span>
                  </div>
                  <div class="d-flex justify-space-between my-2 align-center grey-lighten-2 py-2">
                    <span grey-lighten-2><v-icon class="pr-1 grey-lighten-2">
                      mdi-card-account-details-outline
                    </v-icon>
                      SSN
                    </span>
                    <span>{{ currentuser.ssn }}</span>
                  </div>
                  <div class="d-flex justify-space-between  my-2 mb-2 align-center grey-lighten-2">
                    <span class="grey-lighten-2"><v-icon class="pr-1 grey-lighten-2">
                      mdi-car
                    </v-icon>
                      Drivers License
                    </span>
                    <span>{{ currentuser.dlNumber }}</span>
                  </div>
                  <div
                    class="h4 ml-2 font-weight-bold my-2 align-center grey-lighten-2"
                  >
                    CONTACT
                  </div>
                  <div class="d-flex justify-space-between my-2 align-center mt-2 grey-lighten-2">
                    <span>
                      <v-icon class="pr-1 grey-lighten-22">
                        mdi-phone
                      </v-icon>
                      Phone
                    </span>
                    <span>{{ currentuser.mobile }}</span>
                  </div>
                  <div class="d-flex justify-space-between my-2 align-center grey-lighten-2">
                    <span>
                      <v-icon class="pr-1 mb-2">
                        mdi-map-marker-outline
                      </v-icon>
                      Address
                    </span>
                    <span>{{ currentuser.address /*+ " " + currentuser.address.city + " " + currentuser.address.locality + " " + address.country*/ }}</span>
                  </div>
                </v-card-text>
              </v-col>
              <v-card-actions class="mt-n10 text-center d-flex justify-space-between">
                <v-btn
                  text
                  color="blanketbutton"
                >
                  Learn More
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Userprofile',

    data () {
      return {
        snackbar: '',
        timeout: 5000,
        date: null,
        menu: false,
        pickerDate: '',
        alertmessage: '',
        dlState: '',
        dlNumber: '',
        currentuser: {
          email: '',
          ssn: '',
          dob: '',
          height: {
            feet: '',
            inches: ''
          },
          driverslicense: {
            dlState: '',
            dlNumber: ''
          },
          weight: {
            kg: '',
            lb: ''
          },
          id: '',
          alerts: '',
          status: '',
          firstname: 'unset',
          lastname: 'unset',
          mobile: 'unset',
          address: 'unset',
          city: 'unset',
          country: 'USA',
          postcode: 'unset',
        },
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },
    computed: {
    },
    watch: {
      menu (val) {
        val && setTimeout(() => {
          // 年から選ぶようにする
          this.$refs.picker.activePicker = 'YEAR'
          // 選び始めたら初期化
          this.pickerDate = null
        })
      },
    },
    created () {
      this.units = 'standard'
      this.currentuser.height.feet = 0
      this.currentuser.height.inches = 0
      this.currentuser.gender = ''
      this.currentuser.weight = {
        lb: '',
        kg: ''
      }
      this.currentuser = this.$store.getters.getuser
      this.detectNewUser(this.currentuser)
      this.$gtag.pageview('UserProfile')
    },

    methods: {
      getAddressData: function (addressData, placeResultData, id) {
        this.address = addressData
        console.log('google address ', this.address)
        this.currentuser.postcode = addressData.postal_code
        this.currentuser.city = addressData.locality
        this.currentuser.address = addressData.name
        this.currentuser.country = addressData.country
      },
      savedob (date) {
        this.$refs.menu.save(date)
        this.date = date
        this.currentuser.dob = date
      },
      editUserRedirect () {
        this.$router.push('userprofileupdate')
      },
      detectNewUser (user) {
        if (!user.firstname) {
          this.editUserRedirect()
        }
      }
    },
  }
</script>
<style lang="scss" scoped>
.table-fields{
  font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 15px;
width: 100%;
line-height: 20px;
/* identical to box height, or 125% */

display: flex;
align-items: center;
text-align: start;
letter-spacing: 0.4px;
color: #757575;
}

.table-fields-mobile{
  font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 20px;
/* identical to box height, or 125% */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.4px;
color: #757575;
}

.title-text {
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 40px;
text-align: center;
letter-spacing: 0.02em;
color: #00A1B7;
}

.title-text-mobile {
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 40px;
text-align: center;
letter-spacing: 0.02em;
color: #00A1B7;
}

.grey-background{
  background-color: #F5F5F5;
}

.grey-outline {
  border: 1px solid #E0E0E0;
}

.title-subtext {
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 22px;
text-align: center;
letter-spacing: 0.1px;
color: #212121;
}

.policy-txt {
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 140%;
/* or 18px */

letter-spacing: -0.04em;

/* Gray/600 */

color: #757575;
}
</style>
